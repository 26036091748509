import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import vSelect from 'vue-select';

import SelectItem from '@/common/interfaces/SelectItem';
import { BREAKPOINTS } from '@/common/interfaces/SizeBreakpoints';

import InputValidationMixin from '@/forms/FormElements/Mixins/InputValidationMixin';

import 'vue-select/dist/vue-select.css';
import { fixDropdownAudioEye } from '@/utils/domUtils';

@Component({ components: { vSelect } })
export default class Dropdown extends mixins(InputValidationMixin) {
  @Prop({ type: Array as () => SelectItem[], default: () => [] })
  readonly list!: SelectItem[];

  @Prop({ type: Boolean, default: false })
  readonly filterDuplicates!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String, default: '' })
  readonly placeholder!: string;

  @Prop()
  readonly id!: string;

  @Prop({ type: Boolean, default: false })
  readonly clearable!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: boolean;

  inputSelect(option: SelectItem) {
    let optionVal = option?.Value as any
    if (Array.isArray(option)) optionVal = option
    this.$emit('input', optionVal);
    this.$emit('change', optionVal, this.value);
    this.$emit('fullOption', option)
  }
  mounted() {
    if (this.labelValue) {
      const option = this.list.find(item => item.Text === this.labelValue)
      this.$emit('fullOption', option)
    }

    fixDropdownAudioEye(this.$el, this.list.slice(0, 2), this.clearable)
  }
  fixUlOnClose() {
    setTimeout(() => {
      fixDropdownAudioEye(this.$el, this.list.slice(0, 2), this.clearable)
    }, 500);
  }

  filterBy(option, label, search) {
    const currentOption = (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1;
    if (this.filterDuplicates) {
      if (typeof option.Value !== 'string' && option.Value !== '') {
        return currentOption;
      }
      return null;
    }
    return currentOption;
  }

  get labelValue() {
    const currentItem = this.list.find((i) => i?.Value === this.value);
    return currentItem?.Text;
  }

  get isSearchable() {
    const isDesktop = window.innerWidth >= BREAKPOINTS.sm;
    const notTitleSelect = !this.id?.includes('Person-Title');
    if (isDesktop) {
      return true;
    }
    return notTitleSelect
  }

  get autocomplete() {
    return this.id?.includes('Address') ? 'new-password' : 'off';
  }
}
