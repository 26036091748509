import { Component, Vue } from 'vue-property-decorator';
import IconCross from '@/assets/icons/cross.svg?inline';

const ButtonCloseProps = Vue.extend({
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
});

@Component({
  components: {
    IconCross,
  },
})
export default class ButtonClose extends ButtonCloseProps {
  get ColorFromProps() {
    return 'button-close--color-' + this.color;
  }
}
