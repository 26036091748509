import ApiService from '@/common/ApiService/ApiService';
import { HttpVerb } from '@/common/ApiService/HttpVerb';
import { IGridCard } from './FilterableGridModule.types';
import ApiServiceOptions from '@/common/ApiService/ApiServiceOptions';

export interface GridPage {
  Cards: IGridCard[];
  Paging: PagingResult;
  // only for FE (when you need to replace current page)
  IsFullReplace?: boolean;
}

export interface ApiPaging {
  Start: number;
  PageSize: number;
}

export interface PagingResult {
  Total: number;
  // TODO add full paging result
}

export interface FilterValuesModel {
  [key: string]: any | [];
}

export interface PageOptionsModel extends FilterValuesModel {
  Sorting: string;
  Paging: ApiPaging;
  Filters: FilterValuesModel;
}

export interface FilterCountItem {
  Id: number;
  Count: number;
}

export interface FilterCountsItem {
  [key: string]: FilterCountItem[];
}

export interface FilterCounts {
  FilterCounts: FilterCountsItem;
  TotalCount: number;
}

export const getService = (endPoint: string) => {
  return new ApiService(endPoint, {
    verb: HttpVerb.POST,
    showLoading: false,
    cancel: true,
    returnData: true,
  });
};

export const GetFilterCounts = async (
  endpoint: string,
  data: FilterValuesModel,
): Promise<FilterCounts> => {
  return getService(endpoint).call('GetFilterCounts', data);
};

export const GetPage = async (
  endpoint: string,
  data: PageOptionsModel,
  options: ApiServiceOptions = { showLoading: true },
): Promise<GridPage> => {
  return getService(endpoint).call('GetPage', data, options);
};
