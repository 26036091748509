import { FilterValuesModel, FilterCountItem, FilterCountsItem } from './FilterableGridModule.api';
import CtaLinkItem from '@/common/interfaces/CtaLinkItem';
import LinkItem from '@/common/interfaces/LinkItem';

export interface IGridCard {
  Id?: number;
  CardType?: string;
}

export interface FilterableGridViewModel {
  CtaText?: string;
  DottedLine?: boolean; // show footer dotted line
  Header?: string; // DestinationGrid ("All destinations")
  TitleLarge?: string;
  ShowHeaderCount?: boolean; // DestinationsGrid ("(30) All destinations")
  ShowLeftResultCount?: boolean; // TeamMemberGrid ("800 Results")
  HeaderCta?: CtaLinkItem; // BrochureGrid "Submit Orders" button
  Filters?: FilterItem[];
  CurrentSorting?: string;
  Sorting?: SortingOption[];
  Cards: IGridCard[];
  Masonry?: MasonrySettings;
  Paging?: PagingModel;
  Endpoint?: string;
  EmptyGridMessage?: string; //Message shown if there is no cards
  DisclaimerMessage?: string;
  ViewMoreLink?: LinkItem;
  HideFilters?: boolean;
  HideSorting?: boolean;
  AppliedFilters?: AppliedFilters;
  NoteText?: string;
}

export interface MasonrySettings {
  TopTitle: string;
  MainTitle: string;
  MasonryField: string;
}

export interface SortingOption {
  Title: string;
  Value: string | number;
  Data?: {
    Field: string;
    Direction: 'asc' | 'desc';
  };
}

export interface PagingModel {
  Total: number;
  PageSize: number; // desktop (-1 for all)
  PageSizeMedium?: number; // tablet
  PageSizeSmall?: number; // mobile
  LoadMoreButton?: CtaLinkItem;
  ViewCounts: boolean; // "Viewing 12 of 800" - M061-TeamMemberGrid
  AdditionalButton?: CtaLinkItem; // Not sure about this one
  // for full paging
  // CurrentPage?: number; //
  // AllPages?: number;
  // IsFull?: boolean; // LoadMore is not full
}

export interface FilterItem {
  Header: string;
  Name: string;
  Options?: FilterOption[];
  Groups?: FilterOptionsGroup[];
  IsDropdown?: boolean;
  IsSingleCheckbox?: boolean;
}

export interface FilterOptionsGroup {
  Header: string;
  Options: FilterOption[];
}

export interface FilterOption {
  Text: string;
  Value: string | number;
  Count: number;
  Checked: boolean;
}

export interface SegmentFilterOption {
  type: string,
  value: string,
}
export interface  AppliedFilters
{
    Destination: string,
    Itinerary: string,
    StartDate :string,
    EndDate :string
}

export const getFilterOptions = (filterItem: FilterItem) => {
  if (filterItem.Options) {
    return filterItem.Options;
  }
  if (filterItem.Groups) {
    return filterItem.Groups.map((g) => g.Options).reduce((aggr, item) => [...aggr, ...item], []);
  }
  return [];
};

export const getCheckedOptions = (filterItem: FilterItem): FilterOption[] =>
  getFilterOptions(filterItem).filter((o) => o.Checked);

export const getCheckedFilter = (filterItem: FilterItem, isFinder: boolean): SegmentFilterOption[] =>
  getFilterOptions(filterItem).filter((o) => o.Checked).map(opt => {
    const type = filterItem.Header;
    if (type === 'Dates' && !isFinder) return { value: `${opt.Text} ${String(opt.Value).slice(0, 4)}`, type }
    if (type === 'Dates' && isFinder) {
      if (opt.Text.length === 3) {
        opt.Text = `${opt.Text} ${String(opt.Value).slice(0, 4)}`
      }
      return { value: `${opt.Text.toUpperCase()}`, type }
    }
    return { value: opt.Text, type }
  })

export const getCheckedFilters = (filters: FilterItem[], isFinder = false) => {
  const checkedFilters = filters.map(filter => getCheckedFilter(filter, isFinder));
  return getUnitedCheckedFilters(checkedFilters)
}
export const getUnitedCheckedFilters = (filters: Array<SegmentFilterOption[]>) =>
  filters.reduce((results: SegmentFilterOption[], filter: SegmentFilterOption[]) => {
    if (filter.length) filter.map(filterItem => results.push(filterItem))
    return results;
  }, [])

export const getCheckedValues = (filterItem: FilterItem): any[] => {
  return getCheckedOptions(filterItem).map((o) => o.Value);
};

export const getCheckedCount = (filters: FilterItem[]) =>
  filters.reduce((count, current) => count + getCheckedOptions(current).length, 0);

export const getFilterValues = (filters: FilterItem[]): FilterValuesModel => {
  return filters.reduce(
    (values: FilterValuesModel, filter: FilterItem) => ({
      ...values,
      [filter.Name]: getCheckedValues(filter),
    }),
    {},
  );
};

export const uncheckAll = (filterItem: FilterItem): void =>
  getFilterOptions(filterItem).forEach((o) => (o.Checked = false));

export const setFilterCount = (filterItem: FilterItem, counts: FilterCountItem[]) =>
  getFilterOptions(filterItem).forEach(
    (o) => (o.Count = counts.find((c) => c.Id == o.Value)?.Count || 0),
  );

export const filterCards = (cards: any[], filters: FilterItem[]) => {
  return cards.filter((c) => isFiltersFitCard(c, filters));
};

export const isFiltersFitCard = (card: any[], filters: FilterItem[]) => {
  return filters.every((f) => isFilterFitCard(card, f));
};

export const isFilterFitCard = (card: any, filter: FilterItem) => {
  const checked = getCheckedOptions(filter);
  return !checked.length || checked.some((o) => isOptionFitCard(card, o, filter));
};

export const isOptionFitCard = (card: any, option: FilterOption, filter: FilterItem) => {
  const cardValue = card[filter.Name];

  if (filter.Name === 'Durations') {
    const [start, end] = option.Text.split(' ')[0]
      .split('-')
      .map((n) => parseInt(n));

    return (cardValue as string[]).some((v) => start <= +v && end && +v <= end);
  } else {
    const { Value } = option;

    if (Array.isArray(cardValue)) {
      return cardValue.includes(Value);
    } else {
      return Value == cardValue;
    }
  }
};

export const getFilterCountsByCards = (cards: any[], filters: FilterItem[]) => {
  return filters.reduce((acc: FilterCountsItem, filter) => {
    const filteredCards = filterCards(
      cards,
      filters.filter((f) => f !== filter),
    );
    return {
      ...acc,
      [filter.Name]: [
        ...getFilterOptions(filter).map(
          (o) =>
            ({
              Id: o.Value,
              Count: filteredCards.filter((c) => isOptionFitCard(c, o, filter)).length,
            } as FilterCountItem),
        ),
      ],
    };
  }, {} as FilterCountsItem);
};
