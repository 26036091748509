import { getComponentProps } from "@/utils/vueUtils";
import { Component, Vue } from 'vue-property-decorator';
import Dropdown from '@/forms/FormElements/Inputs/Dropdown/Dropdown.vue';
import CtaButton from '@/components/CtaButton/CtaButton.vue';
import ArrowSmall from '@/assets/icons/arrow-small.svg?inline';
import ButtonClose from '@/components/ButtonClose/ButtonClose.vue';
import { ItineraryFinderFilterModel } from "./ItineraryFinderFilterModule.types";
import { FilterItem, FilterOption, getCheckedFilters, getFilterValues } from "../FilterableGridModule/FilterableGridModule.types";
import { FilterCounts, GetFilterCounts } from "../FilterableGridModule/FilterableGridModule.api";
import cloneDeep from 'lodash/cloneDeep';
import { SegmentElement } from "@/plugins/utm/SegmentElement";
import { getStore } from "@/store";

const ItineraryFinderFilterProps = Vue.extend({
    props: getComponentProps<ItineraryFinderFilterModel>({
        Endpoint: '/api/ItineraryApiService',
        Button: {
            Action: 'Button',
            ButtonStyle: 1,
            Title: 'Go',
            Link: '/itineraries/',
        },
        InitialFiltering: {
            Filters: [
                {
                    "Header": "Destinations",
                    "Name": "Destinations",
                    "Options": [
                        {
                            "Text": "Alaska",
                            "Value": "12",
                            "Count": 7,
                            "Checked": false
                        },
                        {
                            "Text": "Amazon",
                            "Value": "1",
                            "Count": 2,
                            "Checked": false
                        },
                    ],
                    "Groups": null,
                },
                {
                    "Header": "Ship",
                    "Name": "Ship",
                    "Options": [
                        {
                            "Text": "Costa Rica Land Expedition",
                            "Value": "21",
                            "Count": 1,
                            "Checked": false
                        },
                        {
                            "Text": "Delfin II",
                            "Value": "6",
                            "Count": 2,
                            "Checked": false
                        },
                    ],
                    "Groups": null,
                },
            ],
            SortingOptions: [],
        }
    }),
});
@Component({
    components: {
        Dropdown,
        CtaButton,
        ArrowSmall,
        ButtonClose,
    }
})

export default class ItineraryFinderFilter extends ItineraryFinderFilterProps {
    titleText: string = 'Find your expedition';
    isFilterOpen: boolean = false;
    filterResults = {
        destinations: '',
        months: '',
        ships: '',
    };
    buttonOptions: any = {};
    mobileButtonOptions: any = {};
    filters = [];
    initialMappedMonthFilter: any = [];
    mappedMonthFilter: any = [];
    screenWidth: number = 0;
    isHomePage: boolean = false;
    
    handleResizeEvent() {
        this.screenWidth = screen.width;
        this.isMobile && this.isFilterOpen ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll')
    }
    async setFiltersFromQuery(params: URLSearchParams) {
        this.filters.forEach(f => {
            if (f.Name !== 'Durations') {
                let param = params.get(f.Name.toLowerCase());
                if (f.Options) {
                    f.Options.forEach((opt: FilterOption) => { if (opt.Value === param) opt.Checked = true })
                } else if (f.Groups) {
                    f.Groups.forEach(gr => {
                        gr.Options.forEach((opt: FilterOption) => { if (opt.Value === param) opt.Checked = true })
                    })
                }
            }
        });
        Object.keys(this.filterResults).forEach(key => {
            let val = key !== 'months'? params.get(key) : params.get('dates');
            let filterName = key !== 'months' ? key : 'dates';
            this.updateLink(val, filterName);
        });
        await this.postFilters();
    }
    mapDatesFilter() {
        const monthFilter = this.filters.find(f => f.Name === 'Dates');
        const mappedMonthFilterGroups = monthFilter.Groups.reduce((results, group) => {
            group.Options.forEach((opt: FilterOption) => opt.Text = `${opt.Text} ${group.Header}`.toLowerCase());
            results.push(...group.Options);
            return results;
        }, [])
        this.mappedMonthFilter = {...monthFilter, Name: 'Departure Month', Groups: mappedMonthFilterGroups}
        this.initialMappedMonthFilter = {...monthFilter, Name: 'Departure Month', Groups: cloneDeep(mappedMonthFilterGroups)}
    }
    created() {
        this.filters = cloneDeep(this.p.InitialFiltering.Filters);
        this.buttonOptions = {...this.p.Button};
        this.p.IsCollapsed = true;
        this.mobileButtonOptions = {
            ...this.p.Button,
            Action: 'Custom',
            Title: 'Search',
        };
        this.mapDatesFilter();

        if (window.location.search && !window.location.search.includes('email_md5')) this.setFiltersFromQuery(new URLSearchParams(window.location.search));
    }
    mounted() {
        window.addEventListener('resize', this.handleResizeEvent);
        this.handleResizeEvent();
        this.isHomePage = !window.location.href.includes('itineraries')
        if (this.isHomePage) this.buttonOptions.Link = '/book/'
        
        if (this.isMobile) {
            this.buttonOptions.Title = 'Search'
        }
    }
    onInputOpen() {
        if (!this.isMobile) {
            const scrollDiv = this.$el as HTMLElement;
            window.scrollTo({ top: scrollDiv.offsetTop - scrollDiv.offsetTop / 3, behavior: 'smooth'});
        }
    }
    destroyed() {
        window.removeEventListener('resize', this.handleResizeEvent);
    }
    get getfilterResults() {
        return Object.keys(this.filterResults).reduce((results, key) => {
            if (this.filterResults[key]) {
                if (key === 'destinations.name') key = 'destinations'
                if (key === 'ships.name') key = 'ships'
                if (key === 'dateRange') key = 'dates'
                results.push('itin-find-filter--' + key)
            }
            return results
        }, []).join(' ');
    }
    get isMobile() {
        if (!this.p.IsCollapsed) {
            return false
        }
        return this.screenWidth && this.screenWidth < 635;
    }
    get filtersToShow() {
        return this.filters.filter(f => f.Name !== 'Durations' && f.Name !== 'Dates' );
    }
    get isHomePageType() {
        const state = getStore().state
        return state.Meta.Analytics.page_type_name === 'NewHomePage'
    }
    toggleFilter() {
        this.isFilterOpen = !this.isFilterOpen;
        if (this.isMobile) document.body.classList.toggle('no-scroll');
    }
    updateLink(val: string, filterName: string, nameVal?: string) {
        let value = nameVal || val
        if (this.isHomePage) {
            if (filterName === 'Destinations') filterName = 'destinations.name'
            if (filterName === 'Ships') filterName = 'ships.name'
        } else {
            filterName = filterName.toLowerCase()
        }
        const encodedValue = value ? encodeURIComponent(value) : undefined
        this.$set(this.filterResults, filterName, encodedValue);

        let link = Object.keys(this.filterResults).reduce((results, key) => {
            if (this.filterResults[key]) results.push(key + '=' + this.filterResults[key])
            return results
        }, []).join('&');
        const pathname = this.isHomePage ? 'book' : 'itineraries'
        this.$set(this.buttonOptions, 'Link', link ? `/${pathname}?${link}` : `/${pathname}/`);
    }
    productsSearched() {
        const { page_type_id, page_type_name } = getStore().state.Meta.Analytics;
        SegmentElement.sendEvent('productsSearched', {
            filters: getCheckedFilters(this.filters, true),
            module_type_id: this.$attrs['data-segmentid'],
            module_type_name: this.$attrs.type,
            page_type_id,
            page_type_name,
        })
    }
    async postFilters() {
        try {
            const counts = await GetFilterCounts(this.p.Endpoint, getFilterValues(this.filters));
            this.setNewFilters(counts);
        } catch (e) { console.log(e) }
    }
    filterChanged(val: string, filter: FilterItem) {
        const selectedVal = filter.Options.find(opt => val == opt.Value)
        let nameVal = this.isHomePage ? selectedVal && selectedVal.Text : null
        this.updateLink(val, filter.Name, nameVal);
        if (val) {
            filter.Options.forEach((opt: FilterOption) => { 
                if (opt.Checked) opt.Checked = false;
                if (opt.Value == val) opt.Checked = true;
            });
        } else {
            const initialfilter = this.p.InitialFiltering.Filters.find(f => f.Name === filter.Name);
            filter.Options = cloneDeep(initialfilter.Options);
        }
        this.postFilters();
    }
    monthFilterChanged(val: string) {
        const filterName = this.isHomePage ? 'dateRange' : 'dates'
        let valForHomePage = ''
        if (this.isHomePage && val) {
            let formattedDate: string[] = (val.substring(0, 4) + "," + val.substring(4, 6)).split(',')
            const firstDay = new Date(+formattedDate[0], +formattedDate[1] - 1, 1).getTime() / 1000
            const lastDay = new Date(+formattedDate[0], +formattedDate[1], 0).getTime() / 1000
            valForHomePage = firstDay + '%3A' + lastDay
        }
        this.updateLink(valForHomePage || val, filterName);
        let monthFilter = this.filters.find(f => f.Name === 'Dates');
        if (val) {
            monthFilter.Groups.forEach(group => {
                group.Options.forEach((opt: FilterOption) => {
                    if (opt.Checked) opt.Checked = false;
                    if (opt.Value === val) opt.Checked = true;
                });
            });
        } else {
            const initialMonthFilter = this.p.InitialFiltering.Filters.find(f => f.Name === 'Dates');
            monthFilter.Groups = cloneDeep(initialMonthFilter.Groups);
        }
        this.postFilters();
    }
    setNewFilters(counts: FilterCounts) {
        Object.keys(counts.FilterCounts).forEach(filterName => {
            if (filterName !== 'Durations') {
                const initialFilter = this.p.InitialFiltering.Filters.find(f => f.Name === filterName);
                const filter = this.filters.find(f => f.Name === filterName);
                const filterCounts = counts.FilterCounts[filterName];
                
                if (filter && filterCounts) {
                    const updatedFilterOptions = [];
                    filterCounts.forEach(opt => {
                        if (filter.Options) {
                            let checkedOption = filter.Options.find(opt => opt.Checked);
                            let updatedOption = {...initialFilter.Options.find(c => +c.Value === opt.Id)};

                            if (checkedOption && checkedOption.Value == updatedOption.Value) updatedOption.Checked = true;
                            if (updatedOption) updatedFilterOptions.push(updatedOption);
                        } else if (filter.Groups) {
                            let checkedOption = filter.Groups.map((g) => g.Options).reduce((aggr, item) => [...aggr, ...item], []).find(opt => opt.Checked);
                            let updatedOption = {...this.initialMappedMonthFilter.Groups.find(c => +c.Value === opt.Id)};

                            if (checkedOption && checkedOption.Value == updatedOption.Value) updatedOption.Checked = true;
                            if (updatedOption) updatedFilterOptions.push(updatedOption);
                        }
                    });

                    if (filter.Options) {
                        updatedFilterOptions.sort((a, b) => a.Text.localeCompare(b.Text));
                        filter.Options = updatedFilterOptions;
                    } else if (filter.Groups) {
                        updatedFilterOptions.sort((a, b) => a.Value.localeCompare(b.Value));
                        this.mappedMonthFilter.Groups = updatedFilterOptions;
                    };
                };
            }
        });
    }
}
