import axios, { AxiosRequestConfig, CancelTokenSource, AxiosPromise, AxiosError } from 'axios';
import ApiServiceOptions from './ApiServiceOptions';
import { HttpVerb } from './HttpVerb';
import HandleStatusParams from './HandleStatusParams';
import { isLocal, devRoot, isString, isSSR } from '@/utils/commonUtils';
import { hideLoader, showLoader } from '@/utils/loaderUtils';
import { showToastMessage } from '@/utils/toastUtils';
import ToastType from '@/common/interfaces/enums/ToastType';
import { SegmentElement } from '@/plugins/utm/SegmentElement';

export const defaultErrorMessage = 'Something went wrong!';

export default class ApiService {
  private options: ApiServiceOptions;
  private cancelTokens: { [id: string]: CancelTokenSource } = {};
  private cache: { [key: string]: any } = {};
  constructor(service: string, options?: ApiServiceOptions) {
    const defaultOptions: ApiServiceOptions = {
      verb: HttpVerb.GET,
      cancel: true,
      showLoading: true,
      returnData: false,
      handleErrors: true,
      cacheResults: false,
      redirectUrl: false,
    };
    if (isLocal()) {
      service = `${devRoot}${service}`;
    }
    if (isSSR()) {
      service = `${devRoot}${service}`;
    }

    this.options = { service, ...defaultOptions, ...options };
  }

  call(method: string, data?: {}, options?: ApiServiceOptions): Promise<any> {
    options = { ...this.options, ...options };

    let key = '';
    if (options.cacheResults) {
      key = this.getCacheKey(method, data);
      if (this.cache[key] !== undefined) {
        return Promise.resolve(this.cache[key]);
      }
    }

    const axiosParams = this.createAxiosParams(method, options);

    this.addDataToParams(axiosParams, data);

    if (options.cancel) {
      this.addCancelToken(axiosParams);
    }

    let request = axios.request(axiosParams);

    if (options.showLoading) {
      this.showLoading(request, options.redirectUrl !== true);
    }

    if (options.handleErrors) {
      request.then((r) => r, this.handleError);
    }

    if (options.returnData) {
      request = request.then((r) => r.data);
    }

    if (options.cacheResults) {
      request.then((r) => {
        this.cache[key] = r;
      });
    }

    if (options.redirectUrl) {
      request.then((r) => {
        if (isString(options.redirectUrl)) {
          window.location.href = options.redirectUrl.toString();
        } else {
          const redirectObject: { redirectUrl: string } = r as any;
          if (redirectObject.redirectUrl) window.location.href = redirectObject.redirectUrl;
        }
      });
    }

    return request;
  }

  private getCacheKey(method: string, data?: {}) {
    return `${method}:${JSON.stringify(data)}`;
  }

  private handleError(error: AxiosError) {
    if (error && error.response) {
      const resp = error.response;
      const errorCode = resp.status;
      if (errorCode === 400) {
        if (resp.data) {
          let errors = Object.values(resp.data?.ModelState || resp.data);
          if (isString(resp.data)) {
            errors = [resp.data];
          }

          const messages = [];
          errors.forEach((err) => {
            if (Array.isArray(err)) {
              err.forEach((msg) => {
                messages.push({
                  Text: msg,
                  Type: ToastType.Danger,
                });
              });
            } else {
              messages.push({
                Text: err,
                Type: ToastType.Danger,
              });
            }
          });
          if (messages.length) showToastMessage(messages);
        }
      }
      if (errorCode === 401) {
        if (resp.data.Message) {
          window.location.href = resp.data.Message;
        }
      }
      if (errorCode > 401 && errorCode < 500) {
        if (resp.data.Message) {
          showToastMessage([
            {
              Text: resp.data.Message,
              Type: ToastType.Danger,
            },
          ]);
        }
      }

      if (errorCode === 406 && resp.data.Data.Error && resp.data.Data.Error.toLowerCase().includes('captcha')) {
        if (resp.data.Data.Item) {
          SegmentElement.sendEvent('errorReceived', {
            captcha_score: resp.data.Data.Item,
            error_message: resp.data.Data.Error,
          }, true)
        }
        showToastMessage([
          {
            Text: resp.data.Data.Error,
            Type: ToastType.Danger,
          },
        ]);
      }

      if (errorCode >= 500 && errorCode < 600) {
        let message = resp.data.ExceptionMessage;
        if (!message && isString(resp.data)) {
          message = resp.data;
        }

        if (message) {
          showToastMessage([
            {
              Text: message,
              Type: ToastType.Danger,
            },
          ]);
        }
      }
    }
  }

  private createAxiosParams(method: string, options: ApiServiceOptions) {
    const axiosParams: AxiosRequestConfig = {
      method: options.verb,
      baseURL: options.service,
      url: method,
      headers: options.headers,
    };

    return axiosParams;
  }

  private addDataToParams(params: AxiosRequestConfig, data?: {}) {
    if (data) {
      if (params.method === HttpVerb.GET) {
        params.params = data;
      } else {
        params.data = data;
      }
    }
  }

  private addCancelToken(params: AxiosRequestConfig) {
    const callMetaName: string = `${params.method},${params.baseURL},${params.url}`;

    const tokens = this.cancelTokens;

    if (tokens[callMetaName] !== undefined) {
      tokens[callMetaName].cancel();
    }

    const source = axios.CancelToken.source();
    tokens[callMetaName] = source;

    params.cancelToken = source.token;
  }

  private showLoading(request: AxiosPromise, stopOnSuccess: boolean = true) {
    if (request) {
      showLoader();
      if (stopOnSuccess) {
        request.finally(() => {
          hideLoader();
        });
      } else {
        request.catch(() => {
          hideLoader();
        });
      }
    }
  }

  static HandleStatusObject(params: HandleStatusParams) {
    // const { response, success, fail, errorMessage, toastParams } = params;
    const { response, success, fail } = params;

    const status = (response as any).data || response;
    if (status.IsOk) {
      if (success) {
        success(status.Item);
      }
    } else {
      if (fail) {
        fail(status.error, status.Item);
      }

      //   showErrorToast(errorMessage || status.Error || defaultErrorMessage, toastParams);
    }

    return status.IsOk;
  }
}
