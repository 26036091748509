import { HIDE_LOADER, SHOW_LOADER } from '@/store/modules/overlayModule';
import { getStore } from '@/store/index';

export const hideLoader = () => {
  getStore().commit(HIDE_LOADER);
};

export const showLoader = () => {
  getStore().commit(SHOW_LOADER);
};
