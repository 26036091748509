import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import CtaLinkItem, { getButtonClass } from '@/common/interfaces/CtaLinkItem';

import Phone from '@/assets/icons/phone.svg?inline';
import { getStore } from '@/store';

const CtaButtonProps = Vue.extend({
  props: {
    item: Object as () => CtaLinkItem,
    removeClasses: Boolean,
    customClasses: Boolean,
    disabled: Boolean,
  },
});

@Component({ components: { Phone } })
export default class CtaButtonComponent extends CtaButtonProps {
  get link() {
    const { Action, Link } = this.item;
    if (this.item.Action !== 'Custom') {
      let prefix = '';
      switch (Action) {
        case 'Phone':
          {
            prefix = 'tel:';
          }
          break;
        case 'Book':
          {
            return getStore()?.state?.Meta?.BookingUrl as string;
          }
          break;
      }

      return `${prefix}${this.item.Link}`;
    }

    return null;
  }

  get isPhone() {
    return this.item.Action === 'Phone';
  }

  get buttonClass() {
    if (this.customClasses) return '';

    const buttonStyleClass = getButtonClass(this.item.ButtonStyle)

    return {
      'cta-item__call-cta': this.isPhone && !this.removeClasses,
      [buttonStyleClass]: true,
    };
  }

  get downloadTitle() {
    if (this.link && this.link.endsWith('.pdf')) return 'PDF download';
  }

  onLinkClick(e: Event) {
    if (this.link.includes('#')) {
      let btnLink = new URL(`https://www.expeditions.com${this.link}`)
      if (btnLink.pathname === window.location.pathname) {
        e.preventDefault()
        this.$emit('click', `#${this.link.split('#')[1]}`)
      }
    }
  }
}
