import LinkItem from './LinkItem';

export type ActionType = 'Button' | 'Phone' | 'Book' | 'Custom';

export enum CtaButtonStyle {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3,
  White = 4,
  Black = 5,
  Ghost = 6,
  PrimaryDarkBlack = 7,
  None = 99,
}

export default interface CtaLinkItem extends LinkItem {
  Action?: ActionType;
  ButtonStyle?: CtaButtonStyle;
}

export const getButtonClass = (ButtonStyle: CtaButtonStyle) => {
  switch (ButtonStyle) {
    case CtaButtonStyle.Primary:
      return 'button--primary';
    case CtaButtonStyle.Secondary:
      return 'button--secondary';
    case CtaButtonStyle.Tertiary:
      return 'button--tertiary';
    case CtaButtonStyle.White:
      return 'button--white';
    case CtaButtonStyle.Black:
      return 'button--black';
    case CtaButtonStyle.Ghost:
      return 'button--ghost';
    case CtaButtonStyle.PrimaryDarkBlack:
      return 'button--primary button--primary--dark';
    case CtaButtonStyle.None:
      return '';
    default:
      return 'button--primary';
  }
}