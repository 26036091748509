import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class InputValidationMixin extends Vue {
  @Prop(String)
  readonly name!: string;
  @Prop({ type: [String, Number] })
  readonly value!: string;
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  input(value: any) {
    this.$emit('input', value);
  }
  blur() {
    this.$emit('blur');
  }
}
